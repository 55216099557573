// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-event-checkout-success-js": () => import("./../src/pages/event-checkout-success.js" /* webpackChunkName: "component---src-pages-event-checkout-success-js" */),
  "component---src-pages-inde-js": () => import("./../src/pages/inde.js" /* webpackChunkName: "component---src-pages-inde-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-js": () => import("./../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

